/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "verwarming/verwarming-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "verwarming/verwarming-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "verwarming/verwarming10.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal2Image: file(
      relativePath: { eq: "verwarming/verwarming11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Een thermostaat zorgt voor een aangenaam klimaat`,
  inner: [
    'Een goede (klok)thermostaat is onmisbaar voor een goede regeling van de centrale verwarming. Deze zorgt voor een optimaal comfort door de woning op de juiste tijd te voorzien van de juiste temperatuur. Een slimme thermostaat gaat nog een stapje verder. Door uitgekiende technieken kan veel energie bespaard worden en is het mogelijk om de temperatuur op afstand via de smartphone te regelen. Bovendien past de thermostaat zich helemaal aan uw ritme en woning aan.',
  ],
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/verwarming'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Thermostaten = ({ data, path, location }) => {
  const seo = {
    title: `Thermostaten`,
    description: `Een slimme thermostaat biedt veel gemak en comfort. Wij helpen u graag bij het maken van de juiste keuze.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal2Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Slimme thermostaat',
    inner:
      'Een slimme thermostaat biedt veel gemak en comfort. Zo is hij eenvoudig op afstand te bedienen via een app op de smartphone. Daarnaast heeft u controle over en inzicht in het energieverbruik. Verder is een slimme thermostaat zelflerend, wat betekent dat deze zich aanpast aan uw ritme. Komt u iedere werkdag om 18.00 uur thuis, dan zorgt de thermostaat ervoor dat het op dat tijdstip aangenaam warm is. Staat er een raam open? Dan wordt dat gedetecteerd en schakelt de verwarming automatisch uit.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Advies en installatie thermostaten',
    textWithLink: () => (
      <>
        <p>
          Tegenwoordig is het aanbod slimme thermostaten erg groot. Wij helpen u
          graag bij het maken van de juiste keuze. Vervolgens zorgt onze
          installateur voor een vakkundige installatie en juiste afstelling.
        </p>
        <div className='hidden-sm hidden-md hidden-lg'>
          <a href='tel:0642090304'>
            <Button
              role='button'
              variant='contained'
              className='btn btn--secondary heading__button mt-4x '
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </a>
        </div>
        <AniLink fade to='/contact' className='hidden-xs'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </AniLink>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw thermostaten`,
    pitchLine: `Slimme thermostaat, volautomatisch of handbediend.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Thermostaten.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Thermostaten;
